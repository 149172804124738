<template>
  <form @submit.prevent="onSubmit" class="add-chore-form">
    <div class="form-group">
      <label for="chore-name">Name</label>
      <input id="chore-name" v-model="chore.name" type="text" placeholder="Chore Name" required />
    </div>

    <div class="form-group">
      <label for="chore-due-date">Due Date</label>
      <input id="chore-due-date" v-model="chore.due_date" type="date" required />
    </div>

    <div class="form-group">
      <label for="chore-interval">Interval (days)</label>
      <input id="chore-interval" v-model="chore.interval_days" type="number" required />
    </div>

    <div class="form-actions">
      <button type="submit" class="submit-btn">Add Chore</button>
      <button type="button" class="cancel-btn" @click="onCancel">Cancel</button>
    </div>
  </form>
</template>

<script setup>
import { ref } from 'vue';

const chore = ref({
  name: '',
  due_date: '',
  interval_days: 1,
  done: false, // Default value
  done_by: null, // Default value
  archived: false, // Default value
});

const emit = defineEmits(['addChore', 'cancel']);

const onSubmit = () => {
  console.log('Submitting new chore:', chore.value);
  emit('addChore', chore.value);
  // Reset chore to default values
  chore.value = {
    name: '',
    due_date: '',
    interval_days: 1,
    done: false,
    done_by: null,
    archived: false,
  };
};

const onCancel = () => {
  console.log('Canceling chore creation');
  emit('cancel');
};
</script>

<style scoped>
.add-chore-form {
  background: #202020;
  padding: 1.5rem;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid #444;
  background: #333;
  color: #fff;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.submit-btn,
.cancel-btn {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.submit-btn {
  background-color: #27ae60;
  color: white;
  font-weight: bold;
}

.submit-btn:hover {
  background-color: #2ecc71;
}

.cancel-btn {
  background-color: #e74c3c;
  color: white;
  font-weight: bold;
}

.cancel-btn:hover {
  background-color: #ff6f61;
}
</style>
