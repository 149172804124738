<!-- frontend/src/App.vue -->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>
</script>

<style scoped>
/* Root component styles */
#app {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 1rem;
}
</style>
