﻿import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/components/Login.vue'
import ChoreList from '@/components/ChoreList.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login,
  },
  {
    path: '/chores',
    name: 'ChoreList',
    component: ChoreList,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
